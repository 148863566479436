<template>
  <div class="chat-messages">
    <process-spinner
      v-if="isLoading && !messages.length"
      :processing-message="staticText.loadingMessage"
    />

    <template v-else>
      <template v-for="message in messages">
        <!-- User Message -->
        <div
          v-if="message.content"
          :key="`${message.id}-content`"
          class="message user-message"
          :class="{ 'pending': message.id === pendingMessageId }"
        >
          <div class="message-content">
            <pre>{{ message.content }}</pre>
          </div>
        </div>

        <!-- AI Response -->
        <div
          v-if="message.response || (message.id === pendingMessageId && isWaitingForResponse)"
          :key="`${message.id}-response`"
          class="message ai-message"
        >
          <div class="message-content">
            <div class="response-header">
              <img src="/img/aiAnswerIcons/magic-star-orange.svg" class="star-icon" />
              <bao-copy-button
                v-if="message.response"
                class="copy-button"
                :ref="`copyButton-${message.id}`"
                :shouldInitiateCopyProcess="true"
                @initiate-copy-process="copyResponse(message.response, message.id)"
              />
            </div>
            <template v-if="message.id === pendingMessageId && isWaitingForResponse">
              <div class="loading-bubble">
                <div class="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="response-text mt-3">
                <pre>{{ message.response }}</pre>
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ProcessSpinner from "@/apps/base/ProcessSpinner.vue"
import BaoCopyButton from "@/apps/base/BaoCopyButton.vue"
import { mapGetters } from "vuex"

export default {
  name: "ChatMessages",
  components: {
    ProcessSpinner,
    BaoCopyButton
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    pendingMessageId: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters("chat", ["isWaitingForResponse"]),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  data () {
    return {
      staticTextDefault: {
        loadingMessage: "Loading messages..."
      }
    }
  },
  methods: {
    copyResponse (response, messageId) {
      const copyButton = this.$refs[`copyButton-${messageId}`]
      if (copyButton && copyButton[0]) {
        copyButton[0].copyText(response)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-messages {
  .message {
    display: flex;
    margin-bottom: 16px;

    &.user-message {
      justify-content: flex-end;

      .message-content {
        background: #f0e8de;
        color: $slate80;
        border-radius: 12px 12px 0 12px;
      }
    }

    &.ai-message {
      justify-content: flex-start;

      .message-content {
        background: #faf8f6;
        color: $slate80;
        border-radius: 12px 12px 12px 0;
        padding-top: 24px;
        position: relative;
        border: 1px solid $gray-200;

        .response-header {
          position: absolute;
          top: 8px;
          left: 12px;
          right: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .star-icon {
            width: 16px;
            height: 16px;
          }

          .copy-button {
            opacity: 0;
            transition: opacity 0.2s ease;
          }
        }

        &:hover {
          .copy-button {
            opacity: 1;
          }
        }

        .response-text {
          color: $slate80;
          line-height: 1.5;
        }
      }
    }

    .message-content {
      max-width: 80%;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 1.5;

      pre {
        margin: 0;
        font-family: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .response-text {
        color: $slate80;
        line-height: 1.5;
      }
    }
  }
}

// Loading Animation
.loading-bubble {
  min-width: 60px;

  .typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    span {
      width: 8px;
      height: 8px;
      background: $gray-400;
      border-radius: 50%;
      animation: bounce 1.4s infinite ease-in-out;

      &:nth-child(1) { animation-delay: -0.32s; }
      &:nth-child(2) { animation-delay: -0.16s; }
    }
  }
}

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}
</style>
