import axios from "axios"

const BASE_URL = "/api/cognition"

export default {
  /**
   * Get list of chats for a user
   * @param {number} userId - The user ID
   * @param {number} [callId] - Optional call ID to filter chats
   * @param {Object} [params] - Additional query parameters
   * @returns {Promise<Array>} List of chats
   */
  async listChats (params = {}) {
    return axios.get(`${BASE_URL}/list_chats`, { params })
  },

  /**
   * Start a new chat session
   * @param {string} message - Initial message
   * @param {Object} options - Options object that may contain call_id or filters
   * @returns {Promise<Object>} New chat session details
   */
  async initiateChat (message, options = {}) {
    const payload = {
      message,
      ...options
    }
    return axios.post(`${BASE_URL}/initiate_chat`, payload)
  },

  /**
   * Continue an existing chat
   * @param {number} userId - The user ID
   * @param {string} message - The message to send
   * @param {string} chatId - The chat ID
   * @returns {Promise<Object>} Chat response
   */
  async continueChat (message, chatId) {
    return axios.post(`${BASE_URL}/continue_chat`, {
      message,
      chat_id: chatId
    })
  },

  /**
   * Get chat history
   * @param {string} chatId - The chat ID
   * @returns {Promise<Object>} Chat history with messages
   */
  async getChatHistory (chatId) {
    return axios.get(`${BASE_URL}/chat_history`, {
      params: { chat_id: chatId }
    })
  },

  /**
   * Delete a chat
   * @param {string} chatId - The chat ID to delete
   * @returns {Promise<Object>} Response from the server
   */
  async deleteChat (chatId) {
    return axios.delete(`${BASE_URL}/delete_chat`, {
      data: { chat_id: chatId }
    })
  },

  /**
   * Rename a chat
   * @param {string} chatId - The chat ID to rename
   * @param {string} title - The new title for the chat
   * @returns {Promise<Object>} Response from the server
   */
  async renameChat (chatId, title) {
    return axios.put(`${BASE_URL}/rename_chat`, {
      chat_id: chatId,
      title
    })
  }
}
