import { getUserMediaStream } from "@/utils/microphone"

const MEDIA_RECORDER_OPTIONS = { mimeType: "audio/webm" }
const MEDIA_RECORDER_TIMESLICE = 200

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

class GeneralTranscriber {
  constructor () {
    this.liveTranscriptionSocket = null
    this.liveTranscriptionApiKey = null
    this.keepAliveInterval = null
    this.mediaRecorder = null
    this.onTranscriptCallback = null
    this.recognition = null
    this.usingFallback = false
  }

  async startTranscribing (apiKey, language = null, onTranscript = null) {
    if (!apiKey || !language) { return }

    this.onTranscriptCallback = onTranscript

    try {
      // Try Deepgram first
      this.liveTranscriptionApiKey = apiKey
      await this.setupMediaRecorder()
      await this.setupLiveTranscriptionWebSocket(language)
    } catch (error) {
      console.log("Deepgram setup failed, falling back to browser speech recognition")
      this.usingFallback = true
      await this.setupBrowserSpeechRecognition(language)
    }
  }

  async setupBrowserSpeechRecognition (language) {
    if (!SpeechRecognition) {
      throw new Error("Speech recognition not available")
    }

    this.recognition = new SpeechRecognition()
    this.recognition.continuous = false
    this.recognition.interimResults = true
    this.recognition.lang = language
    this.recognition.maxAlternatives = 1

    this.recognition.onstart = () => {
      console.log("Browser speech recognition started")
    }

    this.recognition.onresult = (event) => {
      const result = event.results[event.results.length - 1]
      const transcript = result[0].transcript

      if (result.isFinal) {
        if (this.onTranscriptCallback) {
          this.onTranscriptCallback(transcript, true)
        }
      } else {
        if (this.onTranscriptCallback) {
          this.onTranscriptCallback(transcript, false)
        }
      }
    }

    this.recognition.onend = () => {
      if (this.recognition && !this.stopping) {
        this.recognition.start()
      }
    }

    this.recognition.start()
  }

  async stopTranscribing () {
    this.stopping = true
    if (this.usingFallback) {
      if (this.recognition) {
        this.recognition.stop()
        this.recognition.onstart = null
        this.recognition.onresult = null
        this.recognition.onend = null
        this.recognition = null
      }
    } else {
      await this.stopLiveTranscription()
      await this.resetState()
    }
    this.stopping = false
    this.usingFallback = false
  }

  async setupLiveTranscriptionWebSocket (language) {
    console.log("GeneralTS: Setting up WebSocket")

    const baseUrlForLiveTranscription = "wss://api.deepgram.com/v1/listen"
    const queryParams = [
      "model=nova-2",
      "replace=bau:Bao&replace=Ÿousand",
      "numerals=true"
    ]
    if (language) {
      queryParams.push(`language=${language.split("_")[0]}`)
    }
    queryParams.push("punctuate=true")

    const DG_URL = `${baseUrlForLiveTranscription}?${queryParams.join("&")}`
    this.liveTranscriptionSocket = new WebSocket(DG_URL, ["token", this.liveTranscriptionApiKey])

    this.liveTranscriptionSocket.onopen = (event) => {
      console.log("GeneralTS: WebSocket connection opened:", event)
      this.startKeepAlive()
      this.mediaRecorder.start(MEDIA_RECORDER_TIMESLICE)
    }

    this.liveTranscriptionSocket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      console.log("GeneralTS: Deepgram onmessage", data, data.channel.alternatives[0].transcript)
      if (data.channel && data.channel.alternatives[0] && data.channel.alternatives[0].transcript) {
        const transcript = data.channel.alternatives[0].transcript
        // const isFinal = data.is_final

        if (this.onTranscriptCallback) {
          this.onTranscriptCallback(transcript)
        }
      }
    }

    this.liveTranscriptionSocket.onerror = async (error) => {
      console.error("GeneralTS: WebSocket encountered an error:", error)
      // Instead of stopping, try fallback
      await this.stopLiveTranscription()
      await this.resetState()
      this.usingFallback = true
      await this.setupBrowserSpeechRecognition(language)
    }

    this.liveTranscriptionSocket.onclose = async (event) => {
      if (!this.usingFallback && !this.stopping) {
        console.log("GeneralTS: WebSocket closed unexpectedly, trying fallback")
        await this.resetState()
        this.usingFallback = true
        await this.setupBrowserSpeechRecognition(language)
      }
    }
  }

  async setupMediaRecorder () {
    const stream = await getUserMediaStream()
    this.mediaRecorder = new MediaRecorder(stream, MEDIA_RECORDER_OPTIONS)

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0 && this.liveTranscriptionSocket && this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
        this.liveTranscriptionSocket.send(event.data)
      }
    }
  }

  async stopLiveTranscription () {
    this.stopKeepAlive()
    if (this.liveTranscriptionSocket && this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
      this.liveTranscriptionSocket.send(JSON.stringify({ type: "CloseStream" }))
    }
  }

  startKeepAlive () {
    this.keepAliveInterval = setInterval(() => {
      if (this.liveTranscriptionSocket && this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
        this.liveTranscriptionSocket.send(JSON.stringify({ type: "KeepAlive" }))
        console.log("GeneralTS: Sent KeepAlive message")
      }
    }, 7000)
  }

  stopKeepAlive () {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval)
      this.keepAliveInterval = null
    }
  }

  async resetMediaRecorder () {
    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop()
      await new Promise((resolve) => {
        this.mediaRecorder.onstop = () => {
          if (this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach((track) => track.stop())
          }
          resolve()
        }
      })
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = null
      this.mediaRecorder.onstop = null
      this.mediaRecorder = null
    }
  }

  async resetState () {
    this.stopKeepAlive()
    await this.resetMediaRecorder()
    if (this.liveTranscriptionSocket) {
      if (this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
        console.log("GeneralTS: Closing WebSocket connection")
        this.liveTranscriptionSocket.close()
      }
      this.liveTranscriptionSocket.onmessage = null
      this.liveTranscriptionSocket.onerror = null
      this.liveTranscriptionSocket.onclose = null
      this.liveTranscriptionSocket = null
      console.log("GeneralTS: WebSocket reset")
    }
    this.liveTranscriptionApiKey = null
  }
}

export default GeneralTranscriber
