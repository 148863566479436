import chatApi from "@/services/api/chatApi"

export default {
  namespaced: true,

  state: {
    chats: [],
    currentChatId: null,
    currentMessages: [],
    isHistoryVisible: false,
    error: null,
    isLoading: false,
    isLoadingMessages: false,
    isWaitingForResponse: false
  },

  mutations: {
    SET_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },

    SET_LOADING_MESSAGES (state, isLoading) {
      state.isLoadingMessages = isLoading
    },

    SET_ERROR (state, error) {
      state.error = error
    },

    SET_CURRENT_CHAT (state, chatId) {
      state.currentChatId = chatId
    },

    SET_CHATS (state, chats) {
      state.chats = chats
    },

    UPDATE_CHAT (state, { chatId, response }) {
      const chat = state.chats.find(c => c.id === chatId)
      if (chat) {
        chat.last_message = response
        chat.message_count += 1
        chat.updated_at = new Date().toISOString()
      }
    },

    ADD_CHAT (state, chat) {
      state.chats.unshift(chat) // Add to beginning of array
    },

    SET_CURRENT_MESSAGES (state, messages) {
      state.currentMessages = messages
    },

    ADD_MESSAGE (state, message) {
      state.currentMessages.push(message)
    },

    UPDATE_LAST_MESSAGE (state, response) {
      const lastMessage = state.currentMessages[state.currentMessages.length - 1]
      if (lastMessage) {
        lastMessage.response = response
      }
    },

    SET_WAITING_FOR_RESPONSE (state, isWaiting) {
      state.isWaitingForResponse = isWaiting
    },

    DELETE_CHAT (state, chatId) {
      state.chats = state.chats.filter(chat => chat.id !== chatId)
      if (state.currentChatId === chatId) {
        state.currentChatId = null
        state.currentMessages = []
      }
    },

    UPDATE_CHAT_TITLE (state, { chatId, title }) {
      const chat = state.chats.find(c => c.id === chatId)
      if (chat) {
        chat.title = title
      }
    }
  },

  actions: {
    async fetchChats ({ commit }, params = {}) {
      commit("SET_LOADING", true)
      try {
        const { data } = await chatApi.listChats(params)
        commit("SET_CHATS", data)
        return data
      } catch (error) {
        commit("SET_ERROR", error.message)
        throw error
      } finally {
        commit("SET_LOADING", false)
      }
    },

    async initiateChat ({ commit, rootGetters }, { message, callId, filters }) {
      commit("SET_LOADING_MESSAGES", true)
      commit("SET_WAITING_FOR_RESPONSE", true)
      try {
        const userMessage = {
          id: Date.now().toString(),
          content: message,
          created_at: new Date().toISOString()
        }

        commit("ADD_MESSAGE", userMessage)

        const payload = {
          message,
          ...(callId ? { call_id: callId } : {}),
          ...(filters ? { filters } : { filters: rootGetters["talkFilters/filtersAsJson"] })
        }

        const { data } = await chatApi.initiateChat(message, payload)

        commit("UPDATE_LAST_MESSAGE", data.response)

        const newChat = {
          id: data.chat_id,
          title: data.title,
          created_at: data.created_at,
          updated_at: data.created_at,
          message_count: 1,
          last_message: data.response,
          meta: data.meta
        }

        commit("ADD_CHAT", newChat)
        commit("SET_CURRENT_CHAT", data.chat_id)
        return data
      } catch (error) {
        commit("SET_ERROR", error.message)
        throw error
      } finally {
        commit("SET_LOADING_MESSAGES", false)
        commit("SET_WAITING_FOR_RESPONSE", false)
      }
    },

    async continueChat ({ commit }, { message, chatId }) {
      commit("SET_LOADING_MESSAGES", true)
      commit("SET_WAITING_FOR_RESPONSE", true)
      try {
        const userMessage = {
          id: Date.now().toString(),
          content: message,
          created_at: new Date().toISOString()
        }
        commit("ADD_MESSAGE", userMessage)

        const { data } = await chatApi.continueChat(message, chatId)

        commit("UPDATE_LAST_MESSAGE", data.response)

        commit("UPDATE_CHAT", {
          chatId,
          response: data.response
        })

        return data
      } catch (error) {
        commit("SET_ERROR", error.message)
        throw error
      } finally {
        commit("SET_LOADING_MESSAGES", false)
        commit("SET_WAITING_FOR_RESPONSE", false)
      }
    },

    async loadChatHistory ({ commit }, chatId) {
      commit("SET_LOADING_MESSAGES", true)
      try {
        const { data } = await chatApi.getChatHistory(chatId)
        commit("SET_CURRENT_MESSAGES", data.messages)
        return data
      } catch (error) {
        commit("SET_ERROR", error.message)
        throw error
      } finally {
        commit("SET_LOADING_MESSAGES", false)
      }
    },

    async deleteChat ({ commit }, chatId) {
      try {
        await chatApi.deleteChat(chatId)
        commit("DELETE_CHAT", chatId)
      } catch (error) {
        commit("SET_ERROR", error.message)
        throw error
      }
    },

    async renameChat ({ commit }, { chatId, title }) {
      try {
        await chatApi.renameChat(chatId, title)
        commit("UPDATE_CHAT_TITLE", { chatId, title })
      } catch (error) {
        commit("SET_ERROR", error.message)
        throw error
      }
    }
  },

  getters: {
    currentChat: state => state.chats.find(chat => chat.id === state.currentChatId),
    isLoading: state => state.isLoading,
    hasError: state => !!state.error,
    errorMessage: state => state.error,
    sortedChats: state => [...state.chats].sort((a, b) =>
      new Date(b.updated_at) - new Date(a.updated_at)
    ),
    isWaitingForResponse: state => state.isWaitingForResponse
  }
}
