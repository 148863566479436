<template>
  <div
    class="chat-history-item"
    :class="{ active: isActive }"
    @click="$emit('click')"
  >
    <div class="chat-title" :class="{ 'editing': isEditing }">
      <div
        v-if="isEditing"
        ref="titleInput"
        contenteditable="true"
        class="title-input"
        @blur="handleTitleBlur"
        @keydown.enter.prevent="handleTitleBlur"
      >{{ chat.title || staticText.newChat }}</div>
      <div v-else class="title-text">{{ chat.title || staticText.newChat }}</div>

      <div class="actions" v-click-outside="closeMenu">
        <button class="btn-menu" @click.stop="toggleMenu">
          <i class="fas fa-ellipsis-v"></i>
        </button>

        <div v-if="showMenu" class="menu-dropdown" @click.stop>
          <button class="menu-item" @click.stop="startRename">
            <i class="fas fa-pen"></i>
            {{ staticText.rename }}
          </button>
          <button class="menu-item delete" @click.stop="confirmDelete">
            <i class="fas fa-trash"></i>
            {{ staticText.delete }}
          </button>
        </div>
      </div>
    </div>
    <div class="chat-preview">
      {{ chatPreview }}
    </div>

    <bao-delete-confirm-modal
      :id="'chat-delete-modal-' + chat.id"
      v-model="showDeleteModal"
      @delete="handleDelete"
    >
      {{ staticText.deleteConfirmation }}
    </bao-delete-confirm-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import BaoDeleteConfirmModal from "@/apps/base/BaoDeleteConfirmModal"
import Vue from "vue"

// Create an event bus if it doesn't exist
if (!window.eventBus) {
  window.eventBus = new Vue()
}

export default {
  name: "ChatHistoryItem",
  components: {
    BaoDeleteConfirmModal
  },
  props: {
    chat: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        newChat: "New Chat",
        noMessages: "No messages yet",
        rename: "Rename",
        delete: "Delete",
        deleteConfirmation: "Are you sure you want to delete this chat? This action cannot be undone."
      },
      showMenu: false,
      isEditing: false,
      showDeleteModal: false
    }
  },
  created () {
    // Listen for menu open events
    window.eventBus.$on("menu-opened", (chatId) => {
      if (chatId !== this.chat.id) {
        this.showMenu = false
      }
    })
  },
  beforeDestroy () {
    // Clean up event listener
    window.eventBus.$off("menu-opened")
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    chatPreview () {
      const lastMessage = this.chat.last_message || ""
      return lastMessage.substring(0, 100) || this.staticText.noMessages
    }
  },
  methods: {
    ...mapActions("chat", ["deleteChat", "renameChat"]),

    toggleMenu () {
      const newState = !this.showMenu
      if (newState) {
        // Emit event to close other menus before opening this one
        window.eventBus.$emit("menu-opened", this.chat.id)
      }
      this.showMenu = newState
    },

    closeMenu () {
      this.showMenu = false
    },

    startRename () {
      this.isEditing = true
      this.showMenu = false
      this.$nextTick(() => {
        const el = this.$refs.titleInput
        el.focus()
        // Place cursor at end of text
        const range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      })
    },

    async handleTitleBlur (e) {
      if (!this.isEditing) return

      const newTitle = e.target.innerText.trim()
      if (newTitle && newTitle !== this.chat.title) {
        try {
          await this.renameChat({
            chatId: this.chat.id,
            title: newTitle
          })
        } catch (error) {
          console.error("Failed to rename chat:", error)
        }
      }
      this.isEditing = false
    },

    confirmDelete () {
      this.showMenu = false
      this.showDeleteModal = true
    },

    async handleDelete () {
      try {
        await this.deleteChat(this.chat.id)
      } catch (error) {
        console.error("Failed to delete chat:", error)
      }
    }
  }
}
</script>

<style lang="scss">
/* Global styles for Font Awesome icons */
.fas {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style lang="scss" scoped>
.chat-history-item {
  padding: 12px 20px;
  cursor: pointer;
  border-left: 3px solid transparent;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background: rgba($orange, 0.05);
  }

  &.active {
    background: rgba($orange, 0.1);
    border-left-color: $orange;
  }

  .chat-title {
    font-size: 14px;
    font-weight: 500;
    color: $slate80;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.editing .title-input {
      background: white;
      border: 1px solid $orange;
      border-radius: 4px;
      padding: 2px 6px;
      outline: none;
      min-width: 100px;
    }

    .actions {
      display: flex;
      align-items: center;
      position: relative;
    }

    .btn-menu {
      background: none;
      border: none;
      padding: 4px 8px;
      color: $slate60;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 14px;
      }

      &:hover {
        color: $slate80;
      }
    }

    .menu-dropdown {
      position: absolute;
      top: 100%;
      right: 0;
      background: white;
      border: 1px solid $gray-200;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      min-width: 120px;

      .menu-item {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        padding: 8px 12px;
        border: none;
        background: none;
        color: $slate80;
        cursor: pointer;
        text-align: left;
        font-size: 13px;

        i {
          font-size: 12px;
          width: 14px;
        }

        &:hover {
          background: rgba($orange, 0.05);
        }

        &.delete {
          color: $red;

          &:hover {
            background: rgba($red, 0.05);
          }
        }
      }
    }
  }

  .chat-preview {
    font-size: 13px;
    color: $slate60;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
