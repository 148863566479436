<!-- A modular speech-to-text component that can be used with any input field -->
<template>
  <div class="speech-to-text">
    <button
      class="mic-button"
      :class="{ active: recognitionRunning }"
      :disabled="disabled"
      @click="toggleSpeechToText"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_16300_6740)" stroke="#7F8197" stroke-linecap="round" stroke-linejoin="round"><path d="M10 .834a2.5 2.5 0 00-2.5 2.5v6.667a2.5 2.5 0 005 0V3.334a2.5 2.5 0 00-2.5-2.5z" fill="#7F8197" stroke-width="2"/><path d="M15.833 8.334v1.667a5.833 5.833 0 01-11.667 0V8.334" stroke-width="2"/><path d="M10 15.834v3.333M6.666 19.166h6.667" stroke-width="1.667"/></g><defs><clipPath id="clip0_16300_6740"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import axios from "axios"
import GeneralTranscriber from "@/apps/call/audioProcessing/generalTranscriber"

export default {
  name: "SpeechToText",
  data () {
    return {
      transcriber: null,
      recognitionRunning: false
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    shouldSave: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      audioLanguage: "auth/userAudioInputLanguage"
    })
  },
  methods: {
    async startRecognition () {
      if (this.disabled) return

      try {
        this.recognitionRunning = true
        document.addEventListener("click", this.handleClickOutside)

        if (!this.transcriber) {
          this.transcriber = new GeneralTranscriber()
        }

        // Get API key from backend
        const response = await axios.get("/api/livetranscription/get_transcription_key/")
        const apiKey = response.data.transcription_api_key

        await this.transcriber.startTranscribing(
          apiKey,
          this.audioLanguage,
          this.handleTranscript
        )
      } catch (error) {
        console.error("Failed to start any speech recognition:", error)
        this.endRecognition()
      }
    },
    handleTranscript (transcript) {
      // Split transcript into words
      const words = transcript.split(" ")
      let currentIndex = 0

      // Type out each word with a small delay
      const interval = setInterval(() => {
        if (currentIndex < words.length) {
          this.$emit("transcript", words[currentIndex])
          currentIndex++
        } else {
          clearInterval(interval)
        }
      }, 70)
    },
    async endRecognition () {
      document.removeEventListener("click", this.handleClickOutside)
      if (this.transcriber) {
        await this.transcriber.stopTranscribing()
      }
      this.recognitionRunning = false
      if (this.shouldSave) {
        this.$emit("save")
      }
    },
    toggleSpeechToText (event) {
      event.preventDefault() // Prevent form submission
      this.recognitionRunning ? this.endRecognition() : this.startRecognition()
    },
    handleClickOutside (event) {
      // Check if click was outside our component
      if (this.recognitionRunning && !this.$el.contains(event.target)) {
        this.endRecognition()
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener("click", this.handleClickOutside)
    if (this.recognitionRunning) {
      this.endRecognition()
    }
  }
}
</script>

<style lang="scss" scoped>
.speech-to-text {
  display: inline-block;

  .mic-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: background-color 0.2s;

    &:hover:not(:disabled) {
      background-color: $slate10;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.active {
      background-color: $orange !important;
      animation: 1s linear infinite pulsating;

      svg path {
        stroke: white !important;
        &:first-child {
          fill: white !important;
        }
      }
    }

    @keyframes pulsating {
      50% {
        transform: scale(1.1);
      }
    }

    img, svg {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
